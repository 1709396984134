export const sortByMonth = (array) => {
  array.sort(function(a,b) {
    var x = a["client"].toLowerCase();
    var y = b["client"].toLowerCase();
    //return x < y ? -1 : x > y ? 1 : 0;
    return x.localeCompare(y);
  });
  array.sort(function(a,b) {
    return a["moisFact"]- b["moisFact"];
  });
  return array
}

export const sortByKey = (array, key) => {
  switch (key) {
    case "atc":
    case "client":
    case "ville":
    case "reference":
    case "format":
    case "fournisseur":
    case "devise":
    case "positionFournisseur":
      array.sort(function(a,b) {
          var x = a[key].toLowerCase();
          var y = b[key].toLowerCase();
          return x.localeCompare(y);
          //return x < y ? -1 : x > y ? 1 : 0;
      })
      break;
    case "contratFournisseur":
    case "factureFournisseur":
    case "facturePa":
    case "aSurveiller":
    case "nouvellePose":
      array.sort(function(x, y) {
        return (x[key] === y[key])? 0 : x[key]? -1 : 1;
      });
      break;
    case "annulationPrevue":
      array.sort(function(x, y) {
        return (x[key] === y[key])? 0 : x[key]? 1 : -1;
      });
      break;
    case "dateDepart":
    case "dateRenouvellement":
    case "dateChangementVisuel":
      array.sort(function(a,b) {
        return Date.parse(a[key])-Date.parse(b[key]);
      });
      break;
    default:
      array.sort(function(a,b) {
        return a[key]- b[key];
      });
      break;
  }
  return array
}
