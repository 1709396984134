import React, { Component } from 'react';
import inputs from '../data/billboards.json'
import Input from './Input';

const ROUTE = '/admin/billboard';
const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';

let widgetPhoto;
let widgetBail;
const cldWidgetOpt = {
  cloudName: "dqi1btegb",
  uploadPreset: "x5pucfov",
  sources: ["local"],
  showAdvancedOptions: true,
  cropping: false,
  multiple: true,
  defaultSource: "local",
  styles: {
      palette: {
          window: "#FFFFFF",
          windowBorder: "#90A0B3",
          tabIcon: "#E00324",
          menuIcons: "#5A616A",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#E00324",
          action: "#FF620C",
          inactiveTabIcon: "#0E2F5A",
          error: "#F44235",
          inProgress: "#E00324",
          complete: "#20B832",
          sourceBg: "#E4EBF1"
      },
      fonts: {
          default: {
              active: true
          }
      }
  }
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.showWidget = this.showWidget.bind(this);
    this.showWidgetBail = this.showWidgetBail.bind(this);
    this.checkUploadResultPhoto = this.checkUploadResultPhoto.bind(this);
    this.checkUploadResultBail = this.checkUploadResultBail.bind(this)

  }

  componentDidMount(){
    widgetPhoto = window.cloudinary.createUploadWidget(cldWidgetOpt, (error, result) => { this.checkUploadResultPhoto(result)});
    widgetBail = window.cloudinary.createUploadWidget(cldWidgetOpt, (error, result) => { this.checkUploadResultBail(result)});
  }

  showWidget(){
    this.props.addPhoto("clear");
    widgetPhoto.open()
  }
  showWidgetBail(){
    this.props.addBail("clear");
    widgetBail.open()
  }

  checkUploadResultPhoto(resultEvent){
    if (resultEvent.event === "success") {
      this.props.addPhoto(resultEvent.info.public_id)
    }
  }

  checkUploadResultBail(resultEvent){
    if (resultEvent.event === "success") {
      this.props.addBail(resultEvent.info.public_id)
    }
  }

  render(){
    //const leftFields = Object.keys(this.props.formvalues).filter(key => rightFields.indexOf(key)===-1);
    const leftFields = ['atc','moisFact','client','ville','adresse','reference','format','dateDepart','dureeContrat','dateRenouvellement','anneeDeDepart','dateChangementVisuel','pdv','devise','honoraires'];
    const middleFields = ['fournisseur','observation','positionFournisseur','dpaNet','deviseDPA','taxeMunicipale','margeBrute','contratFournisseur','gps','photoSrc','anneeCF','factureFournisseur','facturePa','aSurveiller','nouvellePose', 'annulationPrevue'];
    const rightFields = ['bailleurNom', 'bailleurAdresse','bailleurCp', 'bailleurVille', 'bailleurTelephone', 'bailleurMail','dateDePaiement','bailleurLoyer','modeDePaiement','lcOuPapier','bailleurObservation','bailPdf'];

    const Inputs = inputs.map(input => {
      if (input.name === "photoSrc" && this.props.formvalues.photoSrc) {
        const imgs = this.props.formvalues.photoSrc.map(photo => {return (<img className = "img-responsive" src = {`${CLD_URL}/c_scale,w_700/${photo}.jpg`} key={photo}></img>)})
        return (
          <div key = {input.name}>
            {imgs}
            <button className = "choose-image" onClick={this.showWidget} key = {input.name}>Choisir un pdf</button>
          </div>
        )
      }
      if (input.name === "bailPdf" ) {
        const imgs = this.props.formvalues.bailPdf ? <img className = "img-responsive" src = {`${CLD_URL}/c_scale,w_700/${this.props.formvalues.bailPdf}.jpg`} key={this.props.formvalues.bailPdf}></img> : ""
        return (
          <div key = {input.name}>
            {imgs}
            <button className = "choose-image" onClick={this.showWidgetBail} key = {input.name}>Choisir un bail en pdf</button>
          </div>
        )
      }
      const val = this.props.formvalues[input.name] ? this.props.formvalues[input.name] : 0;
      return(
        <Input
          inputdata = {input}
          label = {input.label}
          value = {val}
          handleChange = {this.props.handleChange}
          key = {input.name}
        />
      )
    })
    const Right = Inputs.filter(comp => rightFields.indexOf(comp.key)>-1);
    const Left = Inputs.filter(comp => leftFields.indexOf(comp.key)>-1);
    const Middle = Inputs.filter(comp => middleFields.indexOf(comp.key)>-1);

    return (
      <div className="form">
        <h1>{this.props.title}</h1>
        <div className = "container-divisible">
          <div className = "onethird">
            {Left}
          </div>
          <div className = "onethird">
            {Middle}
          </div>
          <div className = "onethird">
            {Right}
          </div>
        </div>
      </div>
    )
  }

}

export default Form;
