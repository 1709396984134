import React, { Component } from 'react';
import Navbar from '../Navbar';
import Filter from '../commons/Filter';
import ToolBar from './Toolbar';
import Input from './Input';
import Form from './Form';
import Tableau from './Tableau';
import {dialogWithDB} from '../lib/communicationWithDB'
import {sortByMonth, sortByKey} from '../lib/sort'
import inputs from '../data/billboards.json'
import './admin.scss'
import fields from '../data/billboards.json'
import { API_URL } from '../apiUrl.js';
import {dateForForm} from '../lib/date'

const ROUTE = '/admin/billboard';
const CLD_URL = 'https://res.cloudinary.com/dqi1btegb/image/upload';

class Admin extends Component {
  constructor (props) {
    super(props);
    this.state = {
      profile: {},
      state: "all",
      selectedId:"",
      form:{
        atc: "",
        moisFact: "",
        client: "",
        ville: "",
        adresse: "",
        reference: "",
        format: "",
        dateDepart: "",
        dureeContrat: 0,
        dateRenouvellement: "",
        pdv: 0,
        devise: "€",
        honoraires: 0,
        fournisseur: "",
        observation: "",
        positionFournisseur: "",
        dpaNet: 0,
        taxeMunicipale: 0,
        margeBrute: 0,
        contratFournisseur: false,
        factureFournisseur: false,
        facturePa: false,
        aSurveiller: false,
        nouvellePose: false,
        annulationPrevue: false,
        gps : "",
        photoSrc: [],
        anneeDeDepart: "",
        dateChangementVisuel:"",
        bailPdf: ""
      },
      panneaux:[],
      allPanneaux:[],
      isFilterOpen: false,
      filters: {
        client:[],
        ville:[],
        format:[],
        adresse:[],
        fournisseur:[],
        moisFact:[],
        atc:[]
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.changeStateTo = this.changeStateTo.bind(this)
    this.changeStateToSelect = this.changeStateToSelect.bind(this)
    this.saveItemInDB = this.saveItemInDB.bind(this)
    this.unarchiveItemInDB = this.unarchiveItemInDB.bind(this)
    this.archiveItemInDB = this.archiveItemInDB.bind(this)
    this.deleteItemInDB = this.deleteItemInDB.bind(this)
    this.duplicateItemInDB = this.duplicateItemInDB.bind(this)
    this.changeItemInDB = this.changeItemInDB.bind(this)
    this.addPhoto = this.addPhoto.bind(this)
    this.addBail = this.addBail.bind(this)
    this.openFilter = this.openFilter.bind(this)
    this.filter = this.filter.bind(this)
    this.sort = this.sort.bind(this)
    this.reinitializeForm = this.reinitializeForm.bind(this)

  }

  componentWillMount() {
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
      });
    } else {
      this.setState({ profile: userProfile });
    }
  }

  componentDidMount(){
    const { getAccessToken } = this.props.auth;
    dialogWithDB(`${API_URL}${ROUTE}`,'GET', "", getAccessToken)
      .then(response => {
        const panneauxSorted = sortByMonth(response.panneaux);
        this.setState({
          panneaux: panneauxSorted,
          allPanneaux : panneauxSorted
        })
      })
      .catch(error => alert(error.message) );
  }

  reinitializeForm(){
    this.setState({
      form:{
        atc: "",
        moisFact: "",
        client: "",
        ville: "",
        adresse: "",
        reference: "",
        format: "",
        dateDepart: "",
        dureeContrat: 0,
        dateRenouvellement: "",
        pdv: 0,
        devise: "€",
        honoraires: 0,
        fournisseur: "",
        observation: "",
        positionFournisseur: "",
        dpaNet: 0,
        taxeMunicipale: 0,
        margeBrute: 0,
        contratFournisseur: false,
        factureFournisseur: false,
        facturePa: false,
        aSurveiller: false,
        nouvellePose: false,
        annulationPrevue: false,
        gps:"",
        photoSrc: [],
        anneeDeDepart: "",
        dateChangementVisuel:"",
        bailPdf: ""
      }
    })
  }

  filter(key, value){
    let filters = this.state.filters;
    const index = filters[key].indexOf(value);
    if(index === -1) {
      filters[key].push(value);
    } else {
      filters[key].splice(index,1)
    }
    this.setState({filters});
    let panneaux = this.state.allPanneaux;
    Object.keys(filters).map(key => {
      if(filters[key].length>0){
        panneaux = panneaux.filter(panneau => (filters[key].indexOf(panneau[key])>-1))
      }
    })
    this.setState({panneaux})
  }

  addPhoto(data){
    let form = this.state.form;
    if (data === "clear") {
      form.photoSrc = [];
      this.setState({form})
    } else {
      form.photoSrc.push(data);
      this.setState({form})
    }
  }

  addBail(data){
    let form = this.state.form;
    if (data === "clear") {
      form.bailPdf = "";
      this.setState({form})
    } else {
      form.bailPdf = data;
      this.setState({form})
    }
  }

  changeStateToSelect(id){
    const selectedId = id;
    let newState = "select";
    if (this.state.state === "archive") {
      newState = "selectarchive"
    }
    this.setState({
      state: newState,
      selectedId
    })
  }

  changeStateTo(e, state) {
    const { getAccessToken } = this.props.auth;
    let selectedId;
    let newState = state;
    switch (state) {
      case "all":
        this.reinitializeForm();
        const panneauxSorted = sortByMonth(this.state.allPanneaux);
        this.setState({
          panneaux: panneauxSorted
        })
        break;
      case "edit":
        let form = this.state.panneaux.filter(panneau => panneau._id === this.state.selectedId)[0];
        selectedId = this.state.selectedId;
        this.setState({form})
        break;
      case "archive":
        dialogWithDB(`${API_URL}${ROUTE}/archived`,'GET', "", getAccessToken)
          .then(response => {
            const panneauxSorted = sortByMonth(response.panneaux);
            this.setState({
              panneaux: panneauxSorted
            })
          })
          .catch(error => alert(error.message) );
        break;
      default:
        selectedId = this.state.selectedId
    }

    this.setState({state: newState,selectedId})
  }

  openFilter(){
    this.setState(prevState => ({isFilterOpen : !prevState.isFilterOpen}))
  }

  saveItemInDB(){
    const { getAccessToken } = this.props.auth;
    const data = this.state.form;
    dialogWithDB(`${API_URL}${ROUTE}`,'POST', data, getAccessToken)
      .then(response => {
        const newBillboard = response.data;
        const newPanneaux = sortByMonth([...this.state.panneaux, newBillboard]);
        this.reinitializeForm();
        this.setState(prevState => ({
          state:"all",
          panneaux : newPanneaux
        }))
      })
      .catch(error => alert(error.message));
  }

  changeItemInDB(){
    const { getAccessToken } = this.props.auth;
    const data = this.state.form;
    const id = this.state.selectedId;
    dialogWithDB(`${API_URL}${ROUTE}/edit/${id}`,'PUT',data, getAccessToken)
      .then(response => {
        const editedBillboard = response.data;
        const panneauxWithoutEditedOne = this.state.panneaux.filter(panneau => panneau._id !== this.state.selectedId);
        const newPanneaux = sortByKey(sortByKey([...panneauxWithoutEditedOne, editedBillboard], "client"), "moisFact");

        this.setState({
          state:"all",
          panneaux: newPanneaux
        });
        this.reinitializeForm();
      })
      .catch(error => alert(error.message));
  }

  archiveItemInDB(){
    const { getAccessToken } = this.props.auth;
    const id = this.state.selectedId;
    dialogWithDB(`${API_URL}${ROUTE}/archive/${id}`,'PUT',[], getAccessToken)
      .then(response => {
        let newBillboards = this.state.panneaux.filter(panneau => panneau._id !== this.state.selectedId)
        this.setState({
          state:"all",
          panneaux: newBillboards,
          allPanneaux:newBillboards,
          selectedId: ""
        })
      })
      .catch(error => alert(error.message));
  }

  unarchiveItemInDB(){
    const { getAccessToken } = this.props.auth;
    const id = this.state.selectedId;
    dialogWithDB(`${API_URL}${ROUTE}/unarchive/${id}`,'PUT',[], getAccessToken)
      .then(response => {
        let unarchivedItem = this.state.panneaux.filter(panneau => panneau._id === this.state.selectedId);
        let newBillboards = this.state.allPanneaux
        newBillboards.push(unarchivedItem[0]);
        this.setState({
          state:"all",
          panneaux: newBillboards,
          allPanneaux:newBillboards,
          selectedId: ""
        })
      })
      .catch(error => alert(error.message));
  }

  deleteItemInDB() {
    const { getAccessToken } = this.props.auth;
    const id = this.state.selectedId;
    dialogWithDB(`${API_URL}${ROUTE}/${id}`,'DELETE',[], getAccessToken)
      .then(response => {
        let newBillboards = this.state.panneaux.filter(panneau => panneau._id !== this.state.selectedId)
        const stateToGo = this.state.state === "selectarchive" ? "archive" : "all"
        this.setState({
          state:stateToGo,
          panneaux: newBillboards,
          selectedId: ""
        })
      })
      .catch(error => alert(error.message));
  }
  duplicateItemInDB() {
    const { getAccessToken } = this.props.auth;
    const id = this.state.selectedId;
    let data = this.state.panneaux.filter(panneau => panneau._id === id)[0];
    dialogWithDB(`${API_URL}${ROUTE}`,'POST', data, getAccessToken)
      .then(response => {
        const newBillboard = response.data;
        const newPanneaux = sortByMonth([...this.state.panneaux, newBillboard]);
        this.reinitializeForm();
        this.setState(prevState => ({
          state:"all",
          panneaux : newPanneaux
        }))
      })
      .catch(error => alert(error.message));
  }

  handleChange(event){
    let keyToChange = event.target.name;
    let newForm = this.state.form;
    switch (event.target.type) {
      case "date" :
        newForm[keyToChange] = event.target.value;
        break;
      case "checkbox" :
        newForm[keyToChange] = !this.state.form[keyToChange];
        break;
      default :
        newForm[keyToChange] = event.target.value;
    }

    if(keyToChange === "dureeContrat" || keyToChange === "dateDepart") {
      if (newForm.dureeContrat && newForm.dateDepart) {
        const daysToAdd = newForm.dureeContrat * 365;
        let dateRenouvellement = new Date(newForm.dateDepart);
        dateRenouvellement.setDate(dateRenouvellement.getDate() + daysToAdd);
        newForm["dateRenouvellement"] = dateForForm(dateRenouvellement);
      }
    }

    if(keyToChange === "pdv" || keyToChange === "dpaNet") {
      // newForm.pdv = Number(newForm.pdv.replace(",", '.').replace(/[^\d.-]/g, ''));
      if (newForm.pdv && newForm.dpaNet) {
        newForm["margeBrute"] = (newForm.pdv - newForm.dpaNet)/newForm.pdv*100;
      }
    }

    this.setState({
      form : newForm
    })
  }

  sort(key){
    let panneauxSorted = sortByKey(this.state.panneaux.slice(0), key);
    this.setState({panneaux: panneauxSorted})
  }

  render() {
    const { profile, message, form } = this.state;
    let Edit = "";
    let TableauPanneaux = "";

    switch (this.state.state) {
      case "add":
      case "edit":
        const title = this.state.state === "add" ? "Nouveau Panneau" : "Modification d'un panneau"
        Edit= <Form
          title = {title}
          formvalues = {this.state.form}
          handleChange = {this.handleChange}
          addPhoto = {this.addPhoto}
          addBail = {this.addBail}/>;
        break;
      case "all":
      case "archive":
      case "select":
      case "selectarchive":
        TableauPanneaux = <Tableau
          panneaux = {this.state.panneaux}
          changeStateToSelect = {this.changeStateToSelect}
          sort = {this.sort}
          selectedId = {this.state.selectedId}
          />
        break;
    }
    const styleContainer = {
      "height" : window.innerHeight-120
    }
    const fieldsToExport = fields.filter(field => field.name !== "photoSrc")

    return (
      <div>
        <Navbar
          auth = {this.props.auth}
          nickname = {profile.nickname}
          openFilter = {this.openFilter}
          isFilterOpen = {this.state.isFilterOpen}
          history={this.props.history}/>

        <Filter
          isFilterOpen = {this.state.isFilterOpen}
          panneaux = {this.state.allPanneaux}
          filter = {this.filter}
          filters = {this.state.filters}/>

        <div className="container" style = {styleContainer}>
          <ToolBar
            state = {this.state.state}
            saveItemInDB = {this.saveItemInDB}
            archiveItemInDB = {this.archiveItemInDB}
            unarchiveItemInDB = {this.unarchiveItemInDB}
            deleteItemInDB = {this.deleteItemInDB}
            duplicateItemInDB = {this.duplicateItemInDB}
            changeStateTo = {this.changeStateTo}
            changeItemInDB = {this.changeItemInDB}
            data={this.state.panneaux}
            fields = {fieldsToExport}
            profile = {profile}
            />

          {TableauPanneaux}
          {Edit}
        </div>
      </div>
    );
  }
}



export default Admin;
